import * as React from "react"
import PropTypes from "prop-types"
import Layout from "../components/Layout/Layout"
import SeoHead from "../components/Scripts/SeoHead"
import BreadcrumbWrapper from "../components/BreadcrumbWrapper/BreadcrumbWrapper"
import { NotFoundHeading } from "../constants/label.constants"
import { redirectionList } from "../constants/redirectionList.constants"
import { gTagScriptConsent } from "../components/Scripts/Gtm.Head"

const NotFoundPage = ({ pageContext }) => {
  const { globalComponents, dataLayer } = pageContext

  const breadCrumbDt = {
    heading: "404 ERROR",
    isBreadcrumb: true,
  }

  const seoData = {
    title: "404 Error | Mondelēz International, Inc.",
  }

  // GTM custom dataLayer Object based on environment
  if (typeof window !== "undefined") {
    const dataLayerObj = {
      ...dataLayer,
      page: {
        url: window.location.href,
        title: "404 Error | Mondelēz International, Inc.",
      },
      content: {
        type: "404 Page",
        category: "",
      },
    }
    window.gtmDataLayer = dataLayerObj
  }
  if (typeof window !== `undefined`) {
    const currentSlug = window.location.pathname
    const redirectUrl = redirectionList?.find(
      urlObj => currentSlug?.toString() === urlObj?.from?.toString()
    )
    if (currentSlug === redirectUrl?.from) {
      window.location.pathname = redirectUrl?.to
    } else if (!currentSlug.endsWith("/")) {
      window.location.pathname = `${currentSlug.toLowerCase()}/`
    }
  }
  return (
    <Layout globalComponents={globalComponents} seoData={seoData}>
      <BreadcrumbWrapper {...breadCrumbDt} />
      <div className={"wrapper404"}>
        <h2 className={"notFoundHeading404"}>{NotFoundHeading.heading}</h2>
        <p className={"notFoundText404"}>{NotFoundHeading.text}</p>
      </div>
    </Layout>
  )
}

export const Head = () => {
  const dataObject = typeof window !== "undefined" ? window?.gtmDataLayer : {}
  return (
    <>
      <script
        id="first-unique-id"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `var MdlzdataLayer = ${JSON.stringify(dataObject)};`,
        }}
      />
      <script
        async
        type="text/javascript"
        dangerouslySetInnerHTML={{ __html: gTagScriptConsent }}
      />
      <SeoHead title="404 Error | Mondelēz International, Inc." />
    </>
  )
}

NotFoundPage.propTypes = {
  pageContext: PropTypes.object,
  globalComponents: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  dataLayer: PropTypes.object,
}

export default NotFoundPage
