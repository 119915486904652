export const redirectionList = [
  { from: "/News/careers-meet-kristin-de-lisle/", to: "/news/careers-meet-kristin-de-lisle/" },
  { from: "/News/careers-beata-shares-the-secret-recipe-of-our-seasonal-campaigns/", to: "/news/careers-beata-shares-the-secret-recipe-of-our-seasonal-campaigns/" },
  { from: "/News/meet-adam-kopera/", to: "/news/meet-adam-kopera/" },
  { from: "/News/meet-nico-tahon/", to: "/news/meet-nico-tahon/" },
  { from: "/News/meet-karol-pacyna/", to: "/news/meet-karol-pacyna/" },
  {
    from: "/News/meet-helen-leonardo/",
    to: "/news/meet-helen-leonardo/",
  },
  {
    from: "/News/Meet-Madeleine-Kern/",
    to: "/news/meet-madeleine-kern/",
  },
  { from: "/News/Meet-Arwa/", to: "/news/meet-arwa/" },
  { from: "/News/Meet-Oksana/", to: "/news/meet-oksana/" },
  {
    from: "/News/Meet-Charlotte---the-Global-eCommerce-Digital-Shelf-Excellence-Lead/",
    to: "/news/meet-charlotte---the-global-ecommerce-digital-shelf-excellence-lead/",
  },
  {
    from: "/News/Meet-our-sales-management-team-from-Hungary/",
    to: "/news/meet-our-sales-management-team-from-hungary/",
  },
  {
    from: "/News/Meet-Alice-Antunes/",
    to: "/news/meet-alice-antunes/",
  },
  {
    from: "/News/Meet-Sandrine-Junior-Brand-Manager-Cadbury/",
    to: "/news/meet-sandrine-junior-brand-manager-cadbury/",
  },
  {
    from: "/News/Meet-Colleen-Director-Global-eComm-Key-Retailers/",
    to: "/news/meet-colleen-director-global-ecomm-key-retailers/",
  },
  {
    from: "/News/Meet-Michelle-Pickering/",
    to: "/news/meet-michelle-pickering/",
  },
  { from: "/News/Meet-Kate/", to: "/news/meet-kate/" },
  { from: "/News/Meet-Kelly/", to: "/news/meet-kelly/" },
  { from: "/News/Meet-Kristen/", to: "/news/meet-kristen/" },
  { from: "/News/Meet-Wilson/", to: "/news/meet-wilson/" },
  {
    from: "/News/Meet-Kshema-from-India/",
    to: "/news/meet-kshema-from-india/",
  },
  { from: "/News/Meet-Agata-Madaj/", to: "/news/meet-agata-madaj/" },
  {
    from: "/News/Meet-Lloyd-from-SSA/",
    to: "/news/meet-lloyd-from-ssa/",
  },
  {
    from: "/News/Meet-Katya-from-UK/",
    to: "/news/meet-katya-from-uk/",
  },
  {
    from: "/News/Meet-Gabriella-De-Luca-from-UK/",
    to: "/news/meet-gabriella-de-luca-from-uk/",
  },
  {
    from: "/News/Meet-Priyanka-from-India/",
    to: "/news/meet-priyanka-from-india/",
  },
  {
    from: "/News/Meet-Mark-from-USA/",
    to: "/news/meet-mark-from-usa/",
  },
  { from: "/News/Meet-Sonia/", to: "/news/meet-sonia/" },
  { from: "/News/Meet-Lucy/", to: "/news/meet-lucy/" },
  { from: "/News/Meet-Nim/", to: "/news/meet-nim/" },
  {
    from: "/News/Meet-Steven-from-China/",
    to: "/news/meet-steven-from-china/",
  },
  { from: "/News/Meet-Rafael/", to: "/news/meet-rafael/" },
  { from: "/News/Meet-Marcelo/", to: "/news/meet-marcelo/" },
  { from: "/News/Meet-Monica-Gray/", to: "/news/meet-monica-gray/" },
  {
    from: "/News/Meet-Mikaela-from-Australia/",
    to: "/news/meet-mikaela-from-australia/",
  },
  { from: "/News/Meet-Michele-USA/", to: "/news/meet-michele-usa/" },
  {
    from: "/News/Meet-Michael-Federici/",
    to: "/news/meet-michael-federici/",
  },
  {
    from: "/News/Meet-Melissa-Senior-Manager-Global-Insights-Excellence/",
    to: "/news/meet-melissa-senior-manager-global-insights-excellence/",
  },
  {
    from: "/News/Meet-Megan-People-Lead-UK/",
    to: "/news/meet-megan-people-lead-uk/",
  },
  {
    from: "/News/Meet-Mayara-from-Brazil/",
    to: "/news/meet-mayara-from-brazil/",
  },
  {
    from: "/News/Meet-Lucia---eCommerce-Director-Europe/",
    to: "/news/meet-lucia---ecommerce-director-europe/",
  },
  {
    from: "/News/Meet-Livia-from-Brazil/",
    to: "/news/meet-livia-from-brazil/",
  },
  { from: "/News/Meet-Leighton/", to: "/news/meet-leighton/" },
  { from: "/News/Meet-Katiria-Lugo/", to: "/news/meet-katiria-lugo/" },
  {
    from: "/News/Meet-Karlson-from-China/",
    to: "/news/meet-karlson-from-china/",
  },
  {
    from: "/News/Meet-Jennifer-our-UK-based-Innovation-Manager/",
    to: "/news/meet-jennifer-our-uk-based-innovation-manager/",
  },
  {
    from: "/News/Meet-Isabel-Gonzalez/",
    to: "/news/meet-isabel-gonzalez/",
  },
  {
    from: "/News/Meet-Hannah-with-secrets/",
    to: "/news/meet-hannah-with-secrets/",
  },
  { from: "/News/Meet-Gladys/", to: "/news/meet-gladys/" },
  {
    from: "/News/Meet-Fredrik---KAM-in-Sweden/",
    to: "/news/meet-fredrik---kam-in-sweden/",
  },
  {
    from: "/News/Meet-Francisco-from-Argentina/",
    to: "/news/meet-francisco-from-argentina/",
  },
  {
    from: "/News/Meet-Fabiana-from-Brazil/",
    to: "/news/meet-fabiana-from-brazil/",
  },
  { from: "/News/Meet-Elizabeth/", to: "/news/meet-elizabeth/" },
  {
    from: "/News/Meet-Elisabeth-Silletto/",
    to: "/news/meet-elisabeth-silletto/",
  },
  {
    from: "/News/Meet-Ebram-from-Egypt/",
    to: "/news/meet-ebram-from-egypt/",
  },
  { from: "/News/Meet-Ebony-Scott/", to: "/news/meet-ebony-scott/" },
  {
    from: "/News/Meet-Diana-our-Business-Development-Manager/",
    to: "/news/meet-diana-our-business-development-manager/",
  },
  {
    from: "/News/Meet-Desree-Project-Engineer-in-South-Africa/",
    to: "/news/meet-desree-project-engineer-in-south-africa/",
  },
  {
    from: "/News/Meet-Deepali-from-our-Global-Analytical-Sciences-team-in-India/",
    to: "/news/meet-deepali-from-our-global-analytical-sciences-team-in-india/",
  },
  {
    from: "/News/Meet-Colleen---Director-Global-eComm-Key-Retailers/",
    to: "/news/meet-colleen---director-global-ecomm-key-retailers/",
  },
  {
    from: "/News/Meet-Claes-Wikner---Key-Account-Manager-Sweden/",
    to: "/news/meet-claes-wikner---key-account-manager-sweden/",
  },
  {
    from: "/News/Meet-Christina-our-Operational-Excellence/",
    to: "/news/meet-christina-our-operational-excellence/",
  },
  {
    from: "/News/Meet-Charles-from-France-to-Switzerland-to-Brussels-to-Sweden-for-MDLZ/",
    to: "/news/meet-charles-from-france-to-switzerland-to-brussels-to-sweden-for-mdlz/",
  },
  { from: "/News/Meet-Chaen/", to: "/news/meet-chaen/" },
  {
    from: "/News/Meet-Carolina-E-commerce-Growth-Acceleration-Lead/",
    to: "/news/meet-carolina-e-commerce-growth-acceleration-lead/",
  },
  {
    from: "/News/Meet-Aparna-our-Food-Safety-Manager-from-India/",
    to: "/news/meet-aparna-our-food-safety-manager-from-india/",
  },
  { from: "/News/Meet-Brittany-Bui/", to: "/news/meet-brittany-bui/" },
  { from: "/News/Meet-Anna-Jones/", to: "/news/meet-anna-jones/" },
  {
    from: "/News/Meet-Bharat-from-UK/",
    to: "/news/meet-bharat-from-uk/",
  },
  {
    from: "/News/Meet-Andrew-from-Brazil/",
    to: "/news/meet-andrew-from-brazil/",
  },
  { from: "/News/Meet-Becky-Tang/", to: "/news/meet-becky-tang/" },
  { from: "/News/Meet-Alistair/", to: "/news/meet-alistair/" },
  {
    from: "/News/Meet-Abraao-from-Brazil/",
    to: "/news/meet-abraao-from-brazil/",
  },
  {
    from: "/News/Meet-Ayesha-who-talks-about-re-energizing-her-career/",
    to: "/news/meet-ayesha-who-talks-about-re-energizing-her-career/",
  },
  {
    from: "/News/Meet-Abi-from-the-UK/",
    to: "/news/meet-abi-from-the-uk/",
  },
  {
    from: "/News/Meet-Wesley---Sr-Director-Commerce-Marketing/",
    to: "/news/meet-wesley---sr-director-commerce-marketing/",
  },
  {
    from: "/News/Meet-Werner---Plant-Director-South-Africa/",
    to: "/news/meet-werner---plant-director-south-africa/",
  },
  { from: "/News/Meet-Vo-Thi-Hong/", to: "/news/meet-vo-thi-hong/" },
  { from: "/News/Meet-Vinitha/", to: "/news/meet-vinitha/" },
  {
    from: "/News/Meet-Vanessa-People-Lead-with-Toblerone-in-Switzerland/",
    to: "/news/meet-vanessa-people-lead-with-toblerone-in-switzerland/",
  },
  {
    from: "/News/Meet-Trushann-Innovation-Manager/",
    to: "/news/meet-trushann-innovation-manager/",
  },
  {
    from: "/News/Meet-Arnold-from-Sweden/",
    to: "/news/meet-arnold-from-sweden/",
  },
  { from: "/News/Meet-Sumita-USA/", to: "/news/meet-sumita-usa/" },
  {
    from: "/News/Meet-Shreya-from-India/",
    to: "/news/meet-shreya-from-india/",
  },
  {
    from: "/News/Meet-Shane-from-the-UK/",
    to: "/news/meet-shane-from-the-uk/",
  },
  { from: "/News/Meet-Sam-Asare/", to: "/news/meet-sam-asare/" },
  {
    from: "/News/Meet-Ruben-Colon-Velazquez/",
    to: "/news/meet-ruben-colon-velazquez/",
  },
  {
    from: "/News/Meet-Richard---Europe-eCommerce-Customer-Lead/",
    to: "/news/meet-richard---europe-ecommerce-customer-lead/",
  },
  {
    from: "/News/Meet-Rehan-our-Product-Developer-in-Research-and-Development/",
    to: "/news/meet-rehan-our-product-developer-in-research-and-development/",
  },
  {
    from: "/News/Meet-Pranay-from-India/",
    to: "/news/meet-pranay-from-india/",
  },
  {
    from: "/News/Meet-Ozge-Hatiboglu/",
    to: "/news/meet-ozge-hatiboglu/",
  },
  {
    from: "/News/Meet-our-Global-eCommerce-Director-Commercial-Strategy-and-Growth/",
    to: "/news/meet-our-global-ecommerce-director-commercial-strategy-and-growth/",
  },
  {
    from: "/News/Meet-Nicole-from-Germany/",
    to: "/news/meet-nicole-from-germany/",
  },
  { from: "/News/Meet-Karen/", to: "/news/meet-karen/" },
  { from: "/News/Meet-Seena/", to: "/news/meet-seena/" },
  {
    from: "/News/Meet-Krutika-from-India/",
    to: "/news/meet-krutika-from-india/",
  },
  { from: "/News/Meet-Samella/", to: "/news/meet-samella/" },
  {
    from: "/News/Meet-Anna-from-Bulgaria/",
    to: "/news/meet-anna-from-bulgaria/",
  },
  {
    from: "/News/Meet-Claudia-from-Argentina/",
    to: "/news/meet-claudia-from-argentina/",
  },
  {
    from: "/News/Meet-Smita-from-India/",
    to: "/news/meet-smita-from-india/",
  },
  {
    from: "/News/Meet-Anna-Szentivanyi/",
    to: "/news/meet-anna-szentivanyi/",
  },
  { from: "/News/Meet-Caitlin/", to: "/news/meet-caitlin/" },
  { from: "/News/Meet-Berivan/", to: "/news/meet-berivan/" },
  {
    from: "/News/Meet-Philip-from-the-UK/",
    to: "/news/meet-philip-from-the-uk/",
  },
  {
    from: "/News/Meet-Laure-Amelie-from-France/",
    to: "/news/meet-laure-amelie-from-france/",
  },
  { from: "/News/Meet-Beatriz-Gil/", to: "/news/meet-beatriz-gil/" },
  {
    from: "/News/Meet-Puja-and-Learn-About-Career-Development/",
    to: "/news/meet-puja-and-learn-about-career-development/",
  },
  { from: "/News/Meet-Isabelle/", to: "/news/meet-isabelle/" },
  { from: "/News/Meet-Kamil/", to: "/news/meet-kamil/" },
  { from: "/News/Meet-Kristina/", to: "/news/meet-kristina/" },
  { from: "/News/Meet-Samara/", to: "/news/meet-samara/" },
  { from: "/News/Meet-Rossana/", to: "/news/meet-rossana/" },
  { from: "/News/Meet-Victoria/", to: "/news/meet-victoria/" },
  { from: "/News/Meet-Akhila/", to: "/news/meet-akhila/" },
  { from: "/News/Meet-Sitong/", to: "/news/meet-sitong/" },
  {
    from: "/News/Meet-Varaporn-from-Thailand/",
    to: "/news/meet-varaporn-from-thailand/",
  },
  {
    from: "/News/Meet-Langa-Marketing-Lead-for-Rest-of-Africa/",
    to: "/news/meet-langa-marketing-lead-for-rest-of-africa/",
  },
  {
    from: "/News/Meet-Rafael-Espesani/",
    to: "/news/meet-rafael-espesani/",
  },
  {
    from: "/News/Meet-Diana-Brand-Manager-Cadbury-Biscuits/",
    to: "/news/meet-diana-brand-manager-cadbury-biscuits/",
  },
  { from: "/News/Meet-Nick-Rogers/", to: "/news/meet-nick-rogers/" },
  {
    from: "/News/Meet-Adam-from-Marketing-in-Australia/",
    to: "/news/meet-adam-from-marketing-in-australia/",
  },
  {
    from: "/News/Meet-Thomas-from-Austria/",
    to: "/news/meet-thomas-from-austria/",
  },
  { from: "/News/Meet-Majella-ANZJ/", to: "/news/meet-majella-anzj/" },
  {
    from: "/News/Meet-Dominik-Senior-Brand-Manager-DACH/",
    to: "/news/meet-dominik-senior-brand-manager-dach/",
  },
  {
    from: "/News/Meet-Nathan-UK-based-Supply-Planner-for-the-Cadbury-Plant-Bars/",
    to: "/news/meet-nathan-uk-based-supply-planner-for-the-cadbury-plant-bars/",
  },
  {
    from: "/News/Meet-Jesse-from-ANZJ/",
    to: "/news/meet-jesse-from-anzj/",
  },
  { from: "/News/Meet-Gabriela/", to: "/news/meet-gabriela/" },
]
